<template>
  <div id="user-detail" :class="{ 'from-bo': isFromBO }" v-loading="isLoading">
    <el-row v-if="isSignUp" type="flex" class="user-detail-row" justify="space-between">
      <span class="text-primary text-bold text-no-wrap m-auto">{{ $t("SIGN_UP") }}</span>
      <el-divider />
    </el-row>
    <div class="content bg-white">
      <el-row v-if="isManagement && !isAdding" type="flex" justify="end" align="middle" class="status-row">
        <span class="el-form-item__label text-bold">{{ $t('STATUS') }}:</span>
        <el-select 
          :disabled="canEdit == false"
          v-model="detail.status"
          :class="getStatusClass(detail.status) + (isMobile ? ' w-100': '')">
          <el-option
            v-for="item in allSelectableStatusListFor()"
            :key="item.value"
            :label="$t(item.value)"
            :value="item.value"
          />
        </el-select>
      </el-row>
      <el-form 
        :model="detail"
        ref="detail"
        :rules="rules"
        label-position="top"
        @submit.native.prevent="handleSubmit">
        <el-row :type="isMobile ? '' : 'flex'" justify="space-between" align="middle" :gutter="40">
          <el-col>
            <el-form-item :label="$t('PROFILE_PIC')" prop="photo">
              <image-upload
                :disabled="canEdit == false"
                :photoFileUpload.sync="detail.photo"
                circle
              />
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item v-if="isManagement && detail.role != null" class="w-100 is-required" :label="$t('USER_ROLE')" prop="role">
              <el-select
                :disabled="canEdit == false"
                class="w-100"
                v-model="detail.role.id"
                :placeholder="$t('ROLE_NAME_PLACEHOLDER')"
              >
                <el-option
                  v-for="el in roleOptions"
                  :key="el.id"
                  :label="el | translate"
                  :value="el.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :type="isMobile ? '' : 'flex'" justify="space-between" align="middle" :gutter="40">
          <el-col>
            <el-form-item class="w-100" :label="$t('FIRST_NAME')" prop="firstName">
              <el-input
                type="text"
                v-model="detail.firstName"
                :placeholder="$t('FIRST_NAME_PLACEHOLDER')"
                :disabled="canEdit == false"
              >
              <template slot="prefix">
                <i class="custom-icon icon-name-placeholder-prefix mini"/>
              </template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item class="w-100" :label="$t('LAST_NAME')" prop="lastName">
              <el-input
                type="text"
                v-model="detail.lastName"
                :placeholder="$t('LAST_NAME_PLACEHOLDER')"
                :disabled="canEdit == false"
              >
              <template slot="prefix">
                <i class="custom-icon icon-name-placeholder-prefix mini"/>
              </template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :type="isMobile ? '' : 'flex'" justify="space-between" align="middle" :gutter="40">
          <el-col>
            <el-form-item class="w-100" :label="$t('EMAIL')" prop="email">
              <el-input
                type="text"
                v-model="detail.email"
                :placeholder="$t('EMAIL_PLACEHOLDER')"
                :disabled="canEdit == false"
              >
              <template slot="prefix">
                <i class="custom-icon icon-email-placeholder-prefix mini"/>
              </template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item class="w-100" :label="$t('PHONE')" prop="phone">
              <el-input
                type="text"
                v-model="detail.phone"
                :placeholder="$t('PHONE_PLACEHOLDER')"
                :disabled="canEdit == false"
              >
              <template slot="prefix">
                <i class="custom-icon icon-phone-placeholder-prefix mini"/>
              </template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :type="isMobile ? '' : 'flex'" justify="start" align="middle" :gutter="40">
          <el-col :span="isMobile? 24 : 12">
            <el-form-item class="w-100" :label="$t('ADDRESS')" prop="address">
              <el-input
                type="text"
                v-model="detail.address"
                :placeholder="$t('ADDRESS_PLACEHOLDER')"
                :disabled="canEdit == false"
              >
              <template slot="prefix">
                <i class="custom-icon icon-address-placeholder-prefix mini"/>
              </template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <template v-if="!isSignUp">
          <h3 class="bank-detail-info">{{ $t("BANK_DETAILS") }}</h3>

          <el-row :type="isMobile ? '' : 'flex'" justify="space-between" align="middle" :gutter="40">
            <el-col>
              <el-form-item class="w-100" :label="$t('BANK_NAME')">
                <el-select
                  class="w-100"
                  v-model="detail.bankName.id"
                  :placeholder="$t('BANK_NAME_PLACEHOLDER')"
                  :disabled="canEdit == false"
                >
                  <el-option
                    v-for="el in bankNameOptions"
                    :key="el.id"
                    :label="el.name"
                    :value="el.id"
                  />
                  <template slot="prefix">
                    <i class="custom-icon icon-bank-placeholder-prefix mini"/>
                  </template>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col>
              <el-form-item class="w-100" :label="$t('BANK_ACC_NUM')" prop="bankAccountNum">
                <el-input
                  type="text"
                  v-model="detail.bankAccountNum"
                  :placeholder="$t('BANK_ACC_NUM_PLACEHOLDER')"
                  :disabled="canEdit == false"
                >
                <template slot="prefix">
                  <i class="custom-icon icon-bank-acc-num-placeholder-prefix mini"/>
                </template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :type="isMobile ? '' : 'flex'" justify="space-between" align="middle" :gutter="40">
            <el-col>
              <el-form-item class="w-100" :label="$t('BANK_ACC_HOLDER_NAME')">
                <el-input
                  type="text"
                  v-model="detail.bankAccountHolderName"
                  :placeholder="$t('BANK_ACC_HOLDER_NAME_PLACEHOLDER')"
                  :disabled="canEdit == false"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col v-if="!isMobile">
              <!-- Leave blank purposely -->
            </el-col>
          </el-row>
        </template>

        <el-row v-else-if="uuid != null" :type="isMobile ? '' : 'flex'" justify="start" align="middle" :gutter="30">
          <el-col>
            <el-form-item class="w-100" :label="$t('REGISTERED_DATE')" prop="createdDate">
              <el-input
                type="text"
                :value="moment(detail.createdDate).format(DATE_FORMAT)"
                disabled
              />
            </el-form-item>
          </el-col>
          <el-col>
            <!-- LEAVE BLANK PURPOSELY -->
          </el-col>
        </el-row>

        <!-- All admin can adjust all admin's coin -->
        <template v-if="isManagement && detail.id != null && canEdit">
          <h3 class="coin-adjustment-info">{{ $t("COIN_ADJUSTMENT") }}</h3>

          <el-row :type="isMobile ? '' : 'flex'" justify="space-between" align="middle" :gutter="40">
            <el-col>
              <el-form-item class="w-100" :label="$t('COIN_BALANCE')">
                <el-input type="text" :value="detail.availableCoins" disabled />
              </el-form-item>
            </el-col>
            <el-col>
              <el-form-item v-if="isAdmin" class="w-100" :label="$t('COIN_S_TO_ADJUST')" prop="coin">
                <el-input 
                  v-model.number="detail.coin"
                  :placeholder="$t('COIN_ADJUSTMENT_PLACEHOLDER')"
                  :disabled="!canAdjustCoin"
                >
                  <el-select 
                    slot="prepend" 
                    v-model="detail.transactionType"
                    class="transaction-type-select"
                  >
                    <el-option :label="$t('IN')" value="IN"/>
                    <el-option :label="$t('OUT')" value="OUT"/>
                  </el-select>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </template>

        <el-row v-if="isProfile" class="action-row" type="flex" justify="center" align="middle" :gutter="30">
          <el-col :span="isMobile ? 12 : 6">
            <el-button class="w-100 button primary" :loading="isLoading" native-type="submit">
              {{ $t("UPDATE_PROFILE") }}
            </el-button>
          </el-col>
          <!-- <el-col :span="isMobile ? 12 : 6">
            <el-button class="w-100 button black reversed-color" :loading="isLoading" @click="logout()">
              {{ $t("LOG_OUT") }}
            </el-button>
          </el-col> -->
        </el-row>
        
        <el-row v-else-if="isSignUp" class="action-row" type="flex" :justify="isMobile ? 'space-between' : 'center'" align="middle" :gutter="30">
          <el-col :span="isMobile ? 12 : 4">
            <el-button class="w-100 button black reversed-color" :loading="isLoading" @click="clearForm()">
              {{ $t("RESET") }}
            </el-button>
          </el-col>
          <el-col :span="isMobile ? 12 : 6">
            <el-button class="w-100 button primary" :loading="isLoading" native-type="submit">
              {{ $t("SIGN_UP") }}
            </el-button>
          </el-col>
        </el-row>

        <page-footer
          v-if="isManagement"
          :isLoading="isLoading"
          :handleBackTo="exit"
          :handleSubmit="canEdit ? () => {} : null"
        />

      </el-form>

      <!-- CHANGE PASSWORD -->
      <el-form
        :model="chgPWForm"
        v-if="isProfile"
        ref="chgPWForm"
        :rules="pwRules"
        label-position="top" 
        @submit.native.prevent="handleChgPW"
      >
        <el-row class="chg-password-row" :type="isMobile ? '' : 'flex'" justify="space-between" align="middle" :gutter="10">
          <el-col>
            <el-form-item class="w-100" :label="$t('OLD_PASSWORD')" prop="oldPW">
              <el-input
                :disabled="canEdit == false"
                ref="oldPW"
                :type="oldPWType"
                v-model="chgPWForm.oldPW"
                :placeholder="$t('PASSWORD_PLACEHOLDER')"
              >
                <template slot="prefix">
                  <i class="custom-icon icon-password-placeholder-prefix mini"/>
                </template>
                <template slot="suffix">
                  <i class="custom-icon mini pointer" 
                    :class="oldPWType === 'password' ? 'icon-eye-show' : 'icon-eye-hide'"
                    @click="showPwd('oldPW')"
                  />
                </template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item class="w-100" :label="$t('NEW_PASSWORD')" prop="newPW">
              <el-input
                ref="newPW"
                :type="newPWType"
                v-model="chgPWForm.newPW"
                :placeholder="$t('PASSWORD_PLACEHOLDER')"
                :disabled="canEdit == false"
              >
                <template slot="prefix">
                  <i class="custom-icon icon-password-placeholder-prefix mini"/>
                </template>
                <template slot="suffix">
                  <i class="custom-icon mini pointer" 
                    :class="newPWType === 'password' ? 'icon-eye-show' : 'icon-eye-hide'"
                    @click="showPwd('newPW')"
                  />
                </template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item class="w-100" :label="$t('RETYPE_PASSWORD')" prop="repeatPW">
              <el-input
                ref="repeatPW"
                :type="repeatPWType"
                v-model="chgPWForm.repeatPW"
                :placeholder="$t('PASSWORD_PLACEHOLDER')"
                :disabled="canEdit == false"
              >
                <template slot="prefix">
                  <i class="custom-icon icon-password-placeholder-prefix mini"/>
                </template>
                <template slot="suffix">
                  <i class="custom-icon mini pointer" 
                    :class="repeatPWType === 'password' ? 'icon-eye-show' : 'icon-eye-hide'"
                    @click="showPwd('repeatPW')"
                  />
                </template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row class="action-row" type="flex" justify="center" align="middle">
          <el-col :span="isMobile ? 12 : 4">
            <el-button class="w-100 button primary" :loading="isLoading" native-type="submit">
              {{ $t("UPDATE_PASSWORD") }}
            </el-button>
          </el-col>
        </el-row>

      </el-form>
    </div>

    <el-row v-if="isSignUp" class="back-row" type="flex" justify="center" align="middle">
      <i class="custom-icon icon-arrow-left mini"/>
      <span class="pointer" @click="goTo('sign-in')">
        {{ $t("BACK_TO_LOGIN") }}
      </span>
    </el-row>
  </div>
</template>

<script>
import { generalMixin } from "@/utils/general-mixin.js"
import { getBankList, getRoleList } from "@/api/utility"
import { create, changePassword, updateCurrentProfile, getCurrentUserProfile } from "@/api/auth"
import { getUserProfile, updateProfile } from "@/api/user"
import { validateNum, validateEmail } from "@/utils/helpers"
import { encrypt, decrypt } from "@/utils/encryption"
import ImageUpload from "@/components/image-upload.vue"
import PageFooter from "@/components/page-footer.vue"
import cloneDeep from "lodash/cloneDeep"
import moment from "moment"

const emptyForm = {
  firstName: "", 
  lastName: "",
  email: "",
  phone: "",
  address: "",
  bankName: { id: null },
  bankAccountNum: "",
  bankAccountHolderName: "",
  photo: null,
  role: { id: null },
  transactionType: "IN",
  coin: 0
}
const emptyChgPWForm = {
  oldPW: "",
  newPW: "",
  repeatPW: ""
}

export default {
  name: "UserDetail",
  mixins: [ generalMixin ],
  components: {
    ImageUpload, PageFooter
  },
  data() {
    return {
      isLoading: false,
      uuid: null,
      bankNameOptions: [],
      roleOptions: [],
      detail: cloneDeep(emptyForm),
      originalDetail: cloneDeep(emptyForm),
      canAdjustCoin: true,
      rules: {
        firstName: [{ required: true, message: this.$t("FIRST_NAME_REQUIRED"), trigger: "blur" }],
        lastName: [{ required: true, message: this.$t("LAST_NAME_REQUIRED"), trigger: "blur" }],
        email: [
          { required: true, message: this.$t("EMAIL_REQUIRED"), trigger: "blur" },
          { validator: this.validateEmail, trigger: "blur" }
        ],
        phone: [
          { required: true, message: this.$t("PHONE_REQUIRED"), trigger: "blur" },
          { validator: this.validatePhone, trigger: 'blur' }
        ],
        coin: [
          { validator: this.validateCoin, trigger: "blur" }
        ],
        bankAccountNum: [
          { validator: this.validateBankAccNum, trigger: 'blur' }
        ]
      },
      pwRules: {
        oldPW: [{ validator: this.validateOldPass, trigger: "blur" }],
        newPW: [{ validator: this.validatePass, trigger: "blur" }],
        repeatPW: [{ validator: this.validatePass2, trigger: "blur" }],
      },

      oldPWType: "password",
      newPWType: "password",
      repeatPWType: "password",
      chgPWForm: cloneDeep(emptyChgPWForm),
      moment
    }
  },
  computed: {
    isProfile() { 
      return this.isFromBO && !this.isManagement
    },
    isSignUp() {
      return !this.isProfile && !this.isManagement && this.uuid == null
    },
    isManagement() {
      return this.isFromBO && this.$route.matched[1]?.path == '/bo/user' 
    },
    isAdding() {
      return this.isManagement && this.uuid == null
    },
    canEdit() {
      if (!this.isProfile && !this.isSignUp) return this.fullAccess && this.detail?.role?.code != 'admin'
      else return true
    },
  },
  methods: {
    exit() {
      this.goTo("/bo/user");
    },
    getBankList() {
      getBankList().then(res => {
        if (res.status == 'ok') {
          this.bankNameOptions = res.result.list
        }
      })
    },
    getRoleList() {
      getRoleList().then(res => {
        if (res.status == 'ok') {
          this.roleOptions = res.result.list
          .filter(el => {
            if (this.canEdit == true) {
              return !(el.code == "gln-admin" || el.code == "admin") // cannot create/edit gln-admin and admin
            } else {
              return true
            }
          })
        }
      })
    },
    // SIGNUP
    validateEmail(_, value, callback) {
      if (validateEmail(value) == null) {
        callback(new Error(this.$t("EMAIL_INVALID")))
      } else {
        callback()
      }
    },
    validatePhone(_, value, callback) {
      if (validateNum(value) != true) {
        callback(new Error(this.$t("ONLY_NUMBER")))
      } else {
        callback()
      }
    },
    validateBankAccNum(_, value, callback) {
      if (value == "" || !value) {
        callback()
      } else if (validateNum(value) != true) {
        callback(new Error(this.$t("ONLY_NUMBER")))
      } else if (String(value).length > 20) {
        callback(new Error(this.$t("MAX_LENGTH_20")))
      } else {
        callback()
      }
    },
    validateCoin(_, value, callback) {
      if (value == "" || !value) {
        callback()
      } else if (Number(value) < 0) {
        callback(new Error(this.$t("CANNOT_NEGATIVE_VALUE")))
      } else if (validateNum(value) != true) {
        callback(new Error(this.$t("ONLY_NUMBER")))
      } else {
        callback()
      }
    },
    clearForm() {
      this.detail = cloneDeep(emptyForm)
    },
    handleSubmit() {
      this.$refs['detail'].validate(async (valid) =>  {
        if (valid) {
          let apiToCall = create
          if (this.isProfile) {
            apiToCall = updateCurrentProfile
          }
          else if (this.uuid != null) {
            apiToCall = updateProfile
            let confirmed = await this.confirmDialogPromises()
            if (!confirmed) return
          }

          this.isLoading = true
          apiToCall(this.parseDetailForAPI(cloneDeep(this.detail))).then(res => {
            if (res.status == "ok") {
              if ((this.isManagement && !this.isAdding) || this.isProfile) {
                this.handleUpdateSuccess()
              } else if (this.isAdding) {
                this.handleAddingSuccess()
              } else {
                this.handleSignUpSuccess()
              }
            }
          })
          .finally(() => {
            this.isLoading = false
          })
        } else {
          this.$notify({
            message: this.$t("INVALID_FORM_MESSAGE"),
            type: 'error',
            duration: this.NOTIFY_DURATION,
            position: this.NOTIFY_POSITION
          })
        }
      })
    },
    async confirmDialogPromises() {
      let confirmed = true

      if (this.detail.coin > 0) {
        // user try to adjust coin
        await this.$confirm(
          this.$t("CONFIRM_ADJUST_COIN", { type: this.$t(this.detail.transactionType), coin: this.detail.coin})
        )
        .then(() => confirmed = true)
        .catch(() => confirmed = false)
      }

      if (
        this.originalDetail.status !== this.detail.status
        && this.detail.status === "INACTIVE"
      ) {
        // user try to deactivate the user
        await this.$confirm(this.$t("CONFIRM_DEACTIVATE_USER"))
        .then(() => confirmed = true)
        .catch(() => confirmed = false)
      }

      return confirmed
    },
    parseDetailForAPI(data) {
      if (data.photo?.upload?.id == null) {
        delete data.photo
      }
      if (data.bankName?.id == null) {
        delete data.bankName
      }
      if (this.isSignUp || this.isProfile) {
        delete data.role
      }
      if (data.coin === "") delete data.coin

      // Encryption
      if (data.bankAccountNum != null && data.bankAccountNum !== "") {
        data.bankAccountNum = encrypt(data.bankAccountNum)
      }
      if (data.bankAccountHolderName != null && data.bankAccountHolderName !== "") {
        data.bankAccountHolderName = encrypt(data.bankAccountHolderName)
      }

      return data
    },
    handleUpdateSuccess() {
      if (!this.isProfile) this.exit()
      this.$notify({
        title: this.$t("SUCCESS"),
        message: this.$t("UPDATE_SUCCESSFULLY"),
        type: 'success',
        duration: this.NOTIFY_DURATION,
        position: this.NOTIFY_POSITION
      })
      this.$store.dispatch("auth/getCurrentUserProfile").then(() => {
        const pathToRedirect = this.$store.state["p2p-market"]?.redirectBackTo
        if (pathToRedirect) {
          if (this.$store.getters.isBankDetailComplete) {
            this.$router.push(pathToRedirect)
            this.$store.commit("p2p-market/REDIRECT_BACK_TO", null)
          } else {
            this.$notify({
              type: "error",
              message: this.$t("ERROR_BANK_INCOMPLETE"),
              duration: this.NOTIFY_DURATION,
              position: this.NOTIFY_POSITION
            })
          }
        }
      })
    },
    handleAddingSuccess() {
      this.exit()
      this.$notify({
        title: this.$t("SUCCESS"),
        message: this.$t("ADDED_SUCCESSFULLY"),
        type: 'success',
        duration: this.NOTIFY_DURATION,
        position: this.NOTIFY_POSITION
      })
    },
    handleSignUpSuccess() {
      this.$router.push('/auth/sign-in')
      this.$notify({
        title: this.$t("SUCCESS"),
        message: this.$t("SIGN_UP_SUCCESS"),
        type: 'success',
        duration: this.NOTIFY_DURATION,
        position: this.NOTIFY_POSITION
      })
    },

    // PROFILE
    getDetail() {
      this.isLoading = true
      let data = null
      let apiToCall = getCurrentUserProfile

      if (this.isManagement && !this.isAdding) {
        apiToCall = getUserProfile
        data = this.uuid
      }
      
      apiToCall(data).then(res => {
        if (res.status == 'ok') {
          if (res.result.bankName == null) {
            res.result.bankName = { id: null }
          }
          // Decryption
          if (res.result.bankAccountNum != null && res.result.bankAccountNum !== "") {
            res.result.bankAccountNum = decrypt(res.result.bankAccountNum)
          }
          if (res.result.bankAccountHolderName != null && res.result.bankAccountHolderName !== "") {
            res.result.bankAccountHolderName = decrypt(res.result.bankAccountHolderName)
          }
          res.result.transactionType = "IN" // default IN
          this.detail = cloneDeep(res.result)
          this.originalDetail = cloneDeep(res.result)
          if (this.detail.status !== "ACTIVE") this.canAdjustCoin = false
        }
      })
      .finally(() => this.isLoading = false)
    },
    showPwd(type) {
      if (this[type+'Type'] === 'password') {
        this[type+'Type'] = ''
      } else {
        this[type+'Type'] = 'password'
      }
      this.$nextTick(() => {
        this.$refs[type].focus()
      })
    },
    validateOldPass(_, value, callback) {
      if (value === "") {
        callback(new Error(this.$t("PASSWORD_INVALID_1")));
      } else {
        callback();
      }
    },
    validatePass(_, value, callback) {
      if (value === "") {
        callback(new Error(this.$t("PASSWORD_INVALID_2")));
      } else if (value == this.chgPWForm.oldPW) {
        callback(new Error(this.$t("PASSWORD_INVALID_3")));
      } else {
        if (this.chgPWForm.repeatPW !== "") {
          this.$refs.chgPWForm.validateField("repeatPW");
        }
        callback();
      }
    },
    validatePass2(_, value, callback) {
      if (value === "") {
        callback(new Error(this.$t("PASSWORD_INVALID_4")));
      } else if (value !== this.chgPWForm.newPW) {
        callback(new Error(this.$t("PASSWORD_INVALID_5")));
      } else {
        callback();
      }
    },
    handleChgPW() {
      this.$refs['chgPWForm'].validate(valid => {
        if (valid) {
          this.isLoading = true
          changePassword({
            oldPassword: this.chgPWForm.oldPW,
            newPassword: this.chgPWForm.newPW,
          }).then(res => {
            if (res?.status == "ok") {
              this.$notify({
                title: this.$t("SUCCESS"),
                message: this.$t("CHANGE_PASSWORD_SUCCESS"),
                type: 'success',
                duration: this.NOTIFY_DURATION,
                position: this.NOTIFY_POSITION
              })
              this.$store.dispatch('auth/setToken', res.result.token)
              this.chgPWForm = cloneDeep(emptyChgPWForm)
            }
          })
          .finally(() => this.isLoading = false)
        } else {
          this.$notify({
            message: this.$t("INVALID_FORM_MESSAGE"),
            type: 'error',
            duration: this.NOTIFY_DURATION,
            position: this.NOTIFY_POSITION
          })
        }
      })
    },
    logout() {
      this.$confirm(this.$t("CONFIRM_LOGOUT"), this.$t("ARE_YOU_SURE"))
      .then(() => {
        this.isLoading = true
        this.$store.dispatch('auth/logout')
        .then(() => {
          this.$notify({
            title: this.$t("SUCCESS"),
            message: this.$t("LOG_OUT_SUCCESS"),
            type: 'success',
            duration: this.NOTIFY_DURATION,
            position: this.NOTIFY_POSITION
          })
        })
        .finally(() => {
          this.isLoading = false
          window.location.href = '/'
        })
      })
      .catch(() => {});
    }
  },
  mounted() {
    this.uuid = this.$route.params?.uuid
    if (!this.isSignUp && !this.isAdding) {
      this.getDetail()
    }
    this.getBankList()
    if (this.isManagement) {
      this.getRoleList()
    }

    if (this.$store.state.auth.oldPW != null) {
      this.chgPWForm.oldPW = this.$store.state.auth.oldPW
      this.$store.commit("auth/SET_OLD_PW", null)
      this.$nextTick(() => {
        window.scrollTo(0, document.body.scrollHeight) // to bottom
      })
    }
  },
  beforeRouteLeave(_, __, next) {
    next()
    this.$store.commit("p2p-market/REDIRECT_BACK_TO", null)
  }
}
</script>

<style lang="scss">
@import "@/assets/style/_variables.scss";

#user-detail {
  width: 1007px;
  margin: auto;
  margin-top: -3rem;
  margin-bottom: 3rem;

  .content {
    box-shadow: 0px 3px 15px #00000015;
    border-radius: 5px;
  }

  .user-detail-row {
    >span {
      margin-right: 10px;
      font-family: $fontBaseBold;
    }
  }

  .status-row {
    padding: 10px;
    >span {
      margin-right: 10px;
      margin-top: 5px;
    }
    .el-input__prefix,
    .el-input__suffix {
      top: 0;
    }
  }

  .el-form {
    padding: 1rem 20px 3rem;
    .el-form-item__label {
      padding-bottom: 0;
    }
    .el-input__inner {
      border: 1px solid #F0F0F0;
    }
    .el-select .el-input__suffix {
      top: 0;
    }
    .bank-detail-info,
    .coin-adjustment-info {
      margin-bottom: 1rem;
    }
    .action-row {
      margin-top: 2rem;
      .button.black.reversed-color {
        color: #A8A8A8;
        border: 1px solid #70707065;
      }
      .button {
        font-family: $fontBaseBold;
      }
    }
    .transaction-type-select {
      margin-left: 0;
      margin-right: 0;
      .el-input {
        width: 6rem;
      }
    }
  }

  .back-row {
    position: fixed;
    bottom: 0;
    z-index: 999;
    display: flex;
    align-items: center;
    width: 100vw;
    left: 0;
    text-align: center;
    background: #F3F5FA 0% 0% no-repeat padding-box;
    padding: 1.5rem 0;
    font-family: $fontBaseBold;
    color: #A8A8A8;
    >i { margin-right: 5px }
  }

  // FOR profile + user detail page
  &.from-bo {
    margin-top: 1rem;
    .chg-password-row {
      margin-top: 2rem;
    }
  }
}



.mobile {
  #user-detail {
    width: 100%;
    margin: 0;
    .el-form {
      padding: 1rem 20px 8rem;
    }
    .back-row {
      position: absolute;
    }
  }
}
</style>